<template>
  <div class="contactUsPageWrap">
    <m-layout-bar>
      <div class="contactUsWrap" :style="{background:'url('+imgUrl+')',backgroundSize:'100% 100%'}">
        <div class="content">
          <img class="title" :src="require('../assets/image/contactUs/title.png')" alt />
          <div class="infoWrap clearfloat">
            <div class="leftLayout">
              <div class="mapWrap">
                <img class="title" :src="require('../assets/image/contactUs/map.png')" alt />
              </div>
            </div>

            <div class="rightLayout">
              <span class="title">乐清慢方适</span>
              <span class="title">文化旅游有限公司</span>
              <span class="address">地址：浙江省乐清市大荆镇下山头村铁定溜溜</span>
              <div class="group phone">
                <img :src="require('../assets/image/contactUs/phone.png')" alt />
                <span class="info_1">联系电话</span>
                <span class="info_2">400-661-5666</span>
              </div>
              <div class="group email">
                <img :src="require('../assets/image/contactUs/email.png')" alt />
                <span class="info_1">邮箱地址</span>
                <span class="info_2">mfstdll@chinatdll.com</span>
              </div>
              <div class="group location">
                <img :src="require('../assets/image/contactUs/address.png')" alt />
                <span class="info_1">定位地址</span>
                <span class="info_2">乐清市大荆镇下山头村铁定溜溜</span>
              </div>
              <span class="time">我们的上班时间：8:00-17:30</span>
              <img class="dec left" :src="require('../assets/image/stay/d_1.png')" alt />
              <img class="dec right" :src="require('../assets/image/stay/d_2.png')" alt />
            </div>
          </div>
        </div>
      </div>
    </m-layout-bar>
  </div>
</template>

<script type="text/ecmascript-6">
import LayoutBar from "@/components/LayoutBar";
export default {
  components: {
    [LayoutBar.name]: LayoutBar
  },
  data() {
    return {
      imgUrl: require("../assets/image/contactUs/bg.png")
    };
  }
};
</script>

<style scoped lang="scss" rel="stylesheet/scss">
@import "src/assets/styles/variables.scss";
.contactUsWrap {
  // width:1920px;
  // background:gray;
  border: 1px solid transparent;
  .content {
    width: 1400px;
    margin: 0 auto;
    border: 1px solid transparent;
    margin-bottom: 70px;
    > .title {
      margin-top: 40px;
    }
    .infoWrap {
      margin-top: 45px;
      border: 1px solid transparent;
      background: $main-white;
      .leftLayout {
        // display:inline-block;
        width: 1040px;
        height: 679px;
        float: left;
        padding: 38px 22px 45px 39px;
        box-sizing: border-box;
        // background:red;
        > .mapWrap {
          width: 961px;
          height: 579px;
          border: 10px solid $main-bgOrange;
          border-radius: 10px;
          margin-right: 22px;
          box-sizing: border-box;
          .title {
            width: 100%;
            height: 100%;
          }
        }
      }

      .rightLayout {
        position: relative;
        // display:inline-block;
        width: 356px;
        height: 679px;
        float: right;
        box-sizing: border-box;
        background: $main-bgOrange;
        color: $main-white;
        padding: 36px 20px 47px;
        > .title {
          display: block;
          font-size: 28px;
          font-weight: bold;
          text-align: center;
        }
        .address {
          display: block;
          height: 80px;
          line-height: 80px;
          font-size: 14px;

          text-align: center;
          margin-top: 35px;
          border-top: 1px dashed $main-white;
        }
        .group {
          margin-bottom: 29px;
          &.group.phone {
            margin-top: 56px;
          }
          > img {
            margin-right: 17px;
          }
          .info_1 {
            margin-right: 17px;
          }
          span {
            font-size: 14px;
          }
        }
        .time,
        .dec {
          position: absolute;
        }
        .time {
          bottom: 50px;
          right: 100px;
        }
        .dec.left {
          bottom: 91px;
          right: 249px;
        }
        .dec.right {
          bottom: 70px;
          right: -36px;
        }
      }
    }
  }
}
</style>
